<script lang="ts">
  import TextField from "./TextField.svelte";
  import { UrlIcon } from "./icons";
  export let name: string = "";
  export let key: string;

  export let limit = 0;
  export let required = false;
  export let placeholder = "";
  export let autofocus = false;
  export let hideBorder = false;
  export let disableErrors = false;
  export let isValid: boolean = false;
  export let allowTelephoneNumber: boolean = false;
  export let disabled: boolean = false;

  let textInput: TextField;

  export function getValue() {
    return textInput.getValue();
  }

  export function focus() {
    textInput.focus();
  }

  export function setIsPeding(pending: boolean) {
    textInput.setIsPeding(pending);
  }

  // The most amazing URL regex I could think of in 10 minutes!
  const URL_REGEXP =
    /^(?:(?:(?:https?|ftp):\/\/))?(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i;
  const EMAIL_URL_REGEXP: RegExp = /^(mailto:)?([A-Z0-9._%+-]+@(?:[A-Z0-9-]+\.)+[A-Z]{2,6})$/i;
  const TEL_URL_REGEXP: RegExp = /^(tel:)?(\+[0-9]{1,3})?([-])?([0-9\-]{7,})$/i;

  function validateUrl(valueToValidate: string | undefined) {
    if (valueToValidate) {
      valueToValidate = valueToValidate.trim();
    }

    let isValidUrlOrEmail = !!valueToValidate?.match(URL_REGEXP) || !!valueToValidate?.match(EMAIL_URL_REGEXP);

    if (allowTelephoneNumber) {
      return isValidUrlOrEmail || !!valueToValidate?.match(TEL_URL_REGEXP);
    }

    return isValidUrlOrEmail;
  }
</script>

<TextField
  bind:this={textInput}
  bind:disabled
  {key}
  {name}
  {limit}
  {required}
  {placeholder}
  {autofocus}
  {hideBorder}
  {disableErrors}
  bind:isValid
  icon={UrlIcon}
  pattern={validateUrl}
  on:keydown />
